
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
// import Multiselect from "@vueform/multiselect";
import ReportService from "@/core/services/car/ReportService";
import UserService from "@/core/services/UserService";
import MasterService from "@/core/services/car/MasterService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import RecordSelect from "../components/recordSelect.vue";
import { getMonthDate, roles } from '@/store/stateless/store';
import Swal from "sweetalert2/dist/sweetalert2.js";

interface IPayload {
  start_date: string;
  end_date: string;
  status: any;
  agent: any;
  per_page: number | string;
  page: number;
}

export default defineComponent({
  // component name
  name: "al-manarah-report",
  components: {
    // Multiselect
    RecordSelect
  },
  data() {
    // constants
    return {
      
    };
  },
  setup() {
    const store = useStore();
    const value2 = ref<string>("");

    let bodyPayloads = ref<IPayload>({
      start_date: "",
      end_date: "",
      status: [],
      agent: [],
      per_page: 50,
      page: 1
    });
    let exportUrl = ref<string>("");
    let date = ref<string>("");
    let error = ref<string>("");

  
    
 

    const defaultBodyPayloads = computed(() => {
      return store.getters.getCommonPayLoad;
    });
    const displayResponseData = computed(() => {
      return store.getters.getAlManarahReport;
    });
    const myPagination = computed(() => {
      return store.getters.getExportPagination;
    });

    const getPaginationUrl = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyPayloads.value.page = currentPage.value;
      ReportService.getAlmanarahReport(bodyPayloads.value);
    };

    const onPerPageChange = (event) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyPayloads.value.per_page = event.target.value;
      ReportService.getAlmanarahReport(bodyPayloads.value);
    };


   
    const generateReport = () => {
      if(value2.value.length > 0)
      {
        ReportService.generateAlmanarahReport({date:value2.value})
        .finally(() => {
          console.log("done");
          value2.value = "";
        });
        
        setTimeout(() => {
          Swal.fire({
              text: "Production Report generation request has been successfully submitted!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
        }, 2000);
        error.value = "";
      }
      else
      {
        error.value =  value2.value === "" ? "required" : "";
      }
    };
  
    const validateInput = () => {
      error.value = value2.value === "" ? "required" : "";
    };
    
    onMounted(() => {
      if(!roles.alManarah.includes(roles.userId())) window.location.href = '/#/404'
      
      setCurrentPageBreadcrumbs("Production Report", ["Reports"]);
      ReportService.getAlmanarahReport(defaultBodyPayloads.value);
    });

    const currentPage = ref(1);

    
    const loading = computed(() => {
      return store.getters.getARLoading;
    });
    return {
      loading,
      roles,
      value2,
      date,
      error,
      validateInput,
      currentPage,
      defaultBodyPayloads,
      displayResponseData,
      myPagination,
      onPerPageChange,
      getPaginationUrl,
      exportUrl,
      bodyPayloads,
      generateReport
    };
  },
  methods: {
    indexMethod(index) {
      return (
        this.myPagination.current_page * this.myPagination.per_page -
        this.myPagination.per_page +
        index +
        1
      );
    }
  }
});
